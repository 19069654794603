import React from "react"
import styled from "styled-components";
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import Card from "../components/Card"
import { Skills } from "../components/Skills"
import styles from "../components/styles/HomeSkills.module.scss"
import REACT from "../images/React.svg"
import TERMINAL from "../images/Terminal.svg"
import NODE from "../images/nodejs.svg";
import HomeInfo from "../components/HomeInfo"
import LARAVEL from "../images/Laravel.svg";


const Intro = styled.div`
  background-color: #004e92;
  padding: 5vh 0 0 0;
  color: #fff;
  position: relative;
  min-height: 50vh;
`

const IntroDetails = styled.div`
 margin: 3rem 0 0 0;
 align-items: center;
 h2 {
  
 }
 @media only screen and (min-width: 1024px) {
  //transform: translate3d(0, 20%, 0);
 }
  @media only screen and (min-width: 960px) {
  margin: 0 0 0 0;
  //transform: translate3d(0, 20%, 0);
 }
`


const Involvement = styled.div`
  margin: 5vh 0 0 0;
  h2 {
    text-align: center;
  }
    @media only screen and (min-width: 960px) {
    margin: -5vh 0 0 0;
 }
`

const Title = styled.h3`
  text-align: center;
`

const Divider = styled.hr`
margin: 1rem auto 1rem auto;
display: block;
height: 2px;
background: hsl(0, 0%, 86%);
width: 100%;
max-width: 100%
`


const Wave = styled.div`
margin: -1rem 0 0 0;
    @media only screen and (min-width: 960px) {
    margin: 0 0 0 0;
 }
svg {

}
`

const Home = styled.div`
position: absolute;
top: 2rem;
left: 1rem;
z-index: 30;
a {
font-size: 1.5rem;
font-weight: 700;
font-family: "Open Sans", sans-serif;

text-decoration: none;
padding: .5rem 1rem;
border: 5px solid #fff;
background-color: #fff;
color: #000;
transition: background-color 400ms, color 400ms, border 400ms;
border-radius: 5px;
&:hover {
background-color: #008DD5;
border: 5px solid #008DD5;
color: #fff;
}
}
`

const contributions = {
  react: (
    <React.Fragment>
      <Title>React</Title>
      <p>I was responsible for the creation of the entire front end. I chose React because of its modularity and
        because of how easy it is to expand later on.</p>
      <p>For the UI, I took advantage of Material UI library which enabled me to roll out a working build
        quickly without sacrificing the user experience.</p>
      <ul>
        <li>Created all components</li>
        <li>Implemented all business & front end logic</li>
        <li>Made sure it was mobile responsive</li>
      </ul>
      <Divider/>

    </React.Fragment>
  ),
  express: (
    <React.Fragment>
      <Title>Node + Express</Title>
      <p>I chose Express because of how easy and minimal it is to setup a working API.</p>
      <ul>
        <li>Implemented CORS</li>
        <li>Created an API</li>
        <li>Made migrations & seeds by hand, no ORM</li>
      </ul>
    </React.Fragment>
  ),
  linux: (
    <React.Fragment>
      <Title>Ubuntu</Title>
      <p>Ubuntu was the OS I chose for the Hyper-V instance hosting various internal applications because of its easy access
        to update to date packages without much work.</p>
      <ul>
        <li>Installed operating system from ISO</li>
        <li>Setup Apache to serve front end</li>
        <li>Used Apache reverse proxy to send API requests to Node JS server.</li>
      </ul>
    </React.Fragment>
  ),
}

const PO = ({data}) => {


  return (
    <Layout>
      <SEO title="Concentration Logs" />
      <Home>
        <Link to="/">Home</Link>
      </Home>
      <Intro>
        <IntroDetails>
          <div className="container fluid">
            <div className="grid vertical-center">
              <div className="column">
                <h3>Purchase & Goods Tracking</h3>
                <p>Keeping staff up to date on requisitions & orders</p>
                <p>I was contracted to address a need for a more seamless tracking system for company orders ranging from
                  desk supplies to seeds and farm equipment. As the Purchasing department became overwhelmed by requests for
                  order updates, this application allowed users to view and take charge of their orders alleviating a large burden
                  for the purchasing department.</p>
              </div>
              <div className="column">
                <Img
                  fluid={data.file.childImageSharp.fluid}
                  alt="Laptop with picture of the app"
                />
              </div>
            </div>
          </div>
        </IntroDetails>

      </Intro>

      <Wave>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#004e92" fill-opacity="1" d="M0,128L48,154.7C96,181,192,235,288,224C384,213,480,139,576,101.3C672,64,768,64,864,80C960,96,1056,128,1152,165.3C1248,203,1344,245,1392,266.7L1440,288L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path></svg>
      </Wave>

      <Involvement>
        <h2>Tech Stack & Contribution</h2>
        <div className="container">
          <div className="grid">
            <div className="column">
              <Card color="sky" expandedContent={contributions.react}>
                <img className={styles.skillLogo} src={REACT} alt="React Logo"/>
              </Card>
            </div>
            <div className="column">
              <Card expandedContent={contributions.express} color="purple">
                <img className={styles.skillLogo} src={NODE} alt="Terminal icon"/>
              </Card>
            </div>
            <div className="column">
              <Card expandedContent={contributions.linux} color="blue">
                <img className={styles.skillLogo} src={TERMINAL} alt="Terminal icon"/>
              </Card>
            </div>
          </div>
        </div>

      </Involvement>

      <HomeInfo/>


    </Layout>
  )
}

export default PO

export const query = graphql`
    query POQuery {
        file(relativePath: { eq: "ps-2.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`